<template>
  <div id="app">
    <top-header />
    <div class="wrapper">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";
import Footer from "@/components/Footer.vue";
import AOS from "aos";

export default {
  components: {
    TopHeader,
    Footer,
  },
  created() {
    document.title = "Observatorio de Cáncer";
    AOS.init({ once: true });
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #8c96a7;
  overflow-x: hidden;
}
.wrapper {
  min-height: 80vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
