<template>
  <div></div>
</template>

<script>
export default {
  created: function() {
    this.$router.push({ name: "Home" });
  },
};
</script>

<style></style>
