<template>
  <div class="home">
    <Landing />
  </div>
</template>

<script>
import Landing from "@/components/Landing.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    Landing,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    query: () => ({
      query: `query {
       patients(limit: 1) {
        id
        identidad
       }
     }`,
    }),
  },
  created() {
    axios
      .post("https://www.registrodecancerhn.com/graphql", this.query())
      .then((response) => {
        console.log("data", (this.data = response.data.data));
      })
      .catch((error) => console.log(error));
  },
};
</script>

<style lang="scss">
.home {
  text-align: center;
}
</style>
